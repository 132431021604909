export const COOKIE_KEY = 'OPTOUTMULTI';
export const COOKIE_MAX_AGE = 365 * 3; // 3 years
export const COOKIE_DOMAIN = getCookieDomain();
const defaultSettings = {
  0: '0',
  c3: '0',
  c2: '0',
  c1: '0',
  c4: '0'
};
function isNonBrowserEnv() {
  return typeof window === 'undefined' || typeof document === 'undefined';
}

/**
 * Returns a string for the cookie's domain that will work for all subdomains
 * of the current domain (e.g. "healthcare.gov") unless we're not on a .gov
 * site right now.
 */
export function getCookieDomain() {
  if (isNonBrowserEnv()) {
    return;
  }
  const parts = window.location.hostname.split('.');
  // Starting from the end and working back means that this logic will now support
  // any number of subdomains in the hostname (including zero).
  const tld = parts[parts.length - 1]; // Last item
  const domain = parts[parts.length - 2]; // Second to last item

  if (domain && tld === 'gov') {
    // From MDN:
    //   Contrary to earlier specifications, leading dots in domain names are ignored,
    //   but browsers may decline to set the cookie containing such dots.
    return `${domain}.${tld}`;
  }
}
function readCookie(name) {
  if (isNonBrowserEnv()) {
    return;
  }
  const item = document.cookie.split(';').map(item => item.trim().split('=')).find(_ref => {
    let [itemName] = _ref;
    return itemName === name;
  });
  const value = item?.[1];
  if (typeof value === 'string') {
    return decodeURI(value);
  }
}
function writeCookie(name, value) {
  if (isNonBrowserEnv()) {
    return;
  }

  // See https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
  const base = `${name}=${encodeURI(value)}`;
  const age = `; max-age=${COOKIE_MAX_AGE}`;
  const domain = COOKIE_DOMAIN ? `; domain=${COOKIE_DOMAIN}` : '';
  const path = '; path=/'; // Should apply to any path on the site

  document.cookie = `${base}${age}${domain}${path}`;
}
export function getPrivacySettings() {
  const cookieString = readCookie(COOKIE_KEY);
  if (!cookieString) {
    if (COOKIE_DOMAIN) {
      // This is a domain where we should already have a cookie defined, so let the
      // developers know that Tealium isn't loaded.
      console.error(`Privacy settings error: ${COOKIE_KEY} cookie is not set. Check to make sure your app has Tealium enabled.`);
    }
    return defaultSettings;
  }
  const pairs = cookieString.split('|');
  const settings = pairs.reduce((obj, pair) => {
    const [key, value] = pair.split(':');
    obj[key] = value;
    return obj;
  }, {});
  return settings;
}
export function setPrivacySettings(settings) {
  const cookieString = Object.keys(settings).map(key => `${key}:${settings[key]}`).join('|');
  writeCookie(COOKIE_KEY, cookieString);
}